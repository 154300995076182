import React from "react"
import "./globalstyles.css"

const GlobalStyles = ({ children }) => {
  return (
    <>
    {children}
    </>
  )
}

export default GlobalStyles
