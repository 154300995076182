// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cart-js": () => import("./../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-checkout-js": () => import("./../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-history-js": () => import("./../src/pages/history.js" /* webpackChunkName: "component---src-pages-history-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-success-js": () => import("./../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-zone-js": () => import("./../src/pages/zone.js" /* webpackChunkName: "component---src-pages-zone-js" */),
  "component---src-templates-product-js": () => import("./../src/templates/Product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

